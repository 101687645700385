var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-12"},[_c('div',[_c('RadioGroup',{model:{value:(_vm.model.id_type),callback:function ($$v) {_vm.$set(_vm.model, "id_type", $$v)},expression:"model.id_type"}},_vm._l((_vm.calculateTypes),function(c,index){return _c('Radio',{key:index,staticClass:"mt-1",attrs:{"label":c.value,"border":""}},[_vm._v(" "+_vm._s(c.label)+" ")])}),1)],1)])]),(_vm.model.id_type == 1)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{staticClass:"mb-1 required"},[_vm._v(_vm._s(_vm.$t('payrollCalculation.orgChartStructure')))]),_c('div',[_c('Select',{staticClass:"from-label",class:{
    'is-invalid': _vm.errors.has(
        'org_chart_structure_id'
    )
},attrs:{"filterable":"","loading":_vm.loading,"placeholder":_vm.$t('payrollCalculation.orgChartStructure'),"clearable":true},model:{value:(_vm.model.org_chart_structure_id),callback:function ($$v) {_vm.$set(_vm.model, "org_chart_structure_id", $$v)},expression:"model.org_chart_structure_id"}},_vm._l((_vm.orgStructure),function(org,index){return _c('Option',{key:index,attrs:{"value":org.org_chart_structure_id}},[_vm._v(" "+_vm._s(org.org_chart_structure)+" ")])}),1),(_vm.errors.has('org_chart_structure_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('org_chart_structure_id'))+" ")]):_vm._e()],1)])]):_vm._e(),(_vm.model.id_type == 3)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{staticClass:"mb-1 required"},[_vm._v(_vm._s(_vm.$t('payrollCalculation.shiftName')))]),_c('div',[_c('Select',{staticClass:"from-label",class:{
                            'is-invalid': _vm.errors.has(
                                'shift_id'
                            )
                        },attrs:{"filterable":"","loading":_vm.loading,"multiple":"","max-tag-count":2},model:{value:(_vm.model.shift_id),callback:function ($$v) {_vm.$set(_vm.model, "shift_id", $$v)},expression:"model.shift_id"}},_vm._l((_vm.shift),function(s,index){return _c('Option',{key:index,attrs:{"value":s.shift_id}},[_vm._v(" "+_vm._s(s.shift_name + '-' + s.shift_type)+" ")])}),1),(_vm.errors.has('shift_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('shift_id'))+" ")]):_vm._e()],1)])]):_vm._e(),(_vm.model.id_type == 2)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-12"},[_c('label',{staticClass:"mb-1 required"},[_vm._v(_vm._s(_vm.$t('payrollCalculation.employee')))]),_c('div',[_c('Select',{staticClass:"from-label",class:{
                            'is-invalid': _vm.errors.has(
                                'employee_id'
                            )
                        },attrs:{"filterable":"","remote":"","remote-method":_vm.searchEmployee,"loading":_vm.loading,"multiple":"","max-tag-count":2},model:{value:(_vm.model.employee_id),callback:function ($$v) {_vm.$set(_vm.model, "employee_id", $$v)},expression:"model.employee_id"}},_vm._l((_vm.employeeProfile),function(emp,index){return _c('Option',{key:index,attrs:{"value":emp.employee_id}},[_vm._v(" "+_vm._s(emp.card_id + '-' + emp.employee_name_kh + '-' + emp.employee_name_en)+" ")])}),1),(_vm.errors.has('employee_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('employee_id'))+" ")]):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"mb-1 required"},[_vm._v(_vm._s(_vm.$t('payrollCalculation.cycleYear')))]),_c('div',[_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_year')
                        },staticStyle:{"width":"320px"},attrs:{"value":_vm.model.cycle_year,"type":"year","format":"yyyy","placeholder":"Select Year"},on:{"on-change":_vm.cycleYearChange}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_year'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"mb-1 required"},[_vm._v(_vm._s(_vm.$t('payrollCalculation.cycleMonth')))]),_c('div',[_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_month')
                        },staticStyle:{"width":"320px"},attrs:{"value":_vm.model.cycle_month,"type":"month","placeholder":"Select Month","format":"MM"},on:{"on-change":_vm.cycleMonthChange}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_month'))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group m-b-10"},[_c('label',{staticClass:"mb-1 required"},[_vm._v(_vm._s(_vm.$t('payrollCalculation.cycleOrder')))]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.cycle_order),expression:"model.cycle_order"}],staticClass:"form-control form-select",class:{
                                'is-invalid': _vm.errors.has('cycle_order')
                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "cycle_order", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.cycleOrderChange]}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t('select')))]),_vm._l((_vm.cycleOrders),function(c){return _c('option',{key:c.value,domProps:{"value":c.value}},[_vm._v(_vm._s(c.text))])})],2),(_vm.errors.has('cycle_order'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_order'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.cycle_order != 1),expression:"model.cycle_order != 1"}],staticClass:"form-group m-b-10"},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('payrollCalculation.exchangeRate')))]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.exchange_rate),expression:"model.exchange_rate"}],staticClass:"form-control",attrs:{"type":"number","disabled":""},domProps:{"value":(_vm.model.exchange_rate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "exchange_rate", $event.target.value)}}})])])])]),_c('div',{staticClass:"tw-text-right"},[_c('ts-button',{attrs:{"waiting":_vm.saving,"color":"success","size":"lg"},on:{"click":function($event){$event.preventDefault();return _vm.calculate.apply(null, arguments)}}},[(!_vm.saving)?_c('i',{staticClass:"fas fa-hand-holding-usd"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('payrollCalculation.calculate')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }