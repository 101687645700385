<template>
    <div>
        <h1 class="page-header">{{ $t('payrollCalculation.pageTitle') }}</h1>
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-justify-start">
                        <Button type="info" ghost @click="addNew">{{
                            $t('addNew')
                        }}</Button>
                    </div>
                    <div class="tw-flex tw-justify-end tw-space-x-2">
                        <DatePicker
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            @on-change="onChangeYear"
                            :value="year"
                        ></DatePicker>
                        <DatePicker
                            type="month"
                            format="MM"
                            placeholder="Select Month"
                            @on-change="onChangeMonth"
                            :value="month"
                        >
                        </DatePicker>
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('payrollCalculation.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="remarks">
                    <div v-if="row.remarks ? row.remarks.length < 45 : true">
                        {{ row.remarks }}
                    </div>
                    <div v-tooltip="row.remarks" v-else>
                        {{ row.remarks.substring(0, 45) + '...' }}
                    </div>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('payrollCalculation.pageTitle')"
                width="700px"
            >
                <form-action
                    ref="form_action"
                    @close="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import FormAction from './form.vue'
import moment from 'moment'
export default {
    name: 'payrollCalculation',
    components: {
        FormAction
    },
    data () {
        return {
            loading: false,
            width: 'width: 300px',
            showForm: false,
            year: moment().format('YYYY'),
            month: moment().format('MM')
        }
    },
    computed: {
        ...mapState('payroll/payrollCalculation', [
            'resources',
            'pagination',
            'cycle_month',
            'cycle_year'
        ]),
        search: {
            get () {
                return this.$store.state.payroll.payrollCalculation.search
            },
            set (newValue) {
                this.$store.commit(
                    'payroll/payrollCalculation/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'payroll/payrollCalculation/RESET_CURRENT_PAGE'
                )
            }
        },
        columns () {
            return [
                {
                    title: this.$t('payrollCalculation.cycleOrder'),
                    key: 'cycle_order',
                    sortable: true
                },
                {
                    title: this.$t('payrollCalculation.cycle'),
                    key: 'cycle_month_and_year',
                    sortable: true,
                    align: 'right'
                },
                {
                    title: this.$t('payrollCalculation.calculatedBy'),
                    key: 'calculated_by',
                    sortable: true
                },
                {
                    title: this.$t('payrollCalculation.calculatedDate'),
                    key: 'calculated_date',
                    sortable: true,
                    align: 'right'
                },
                {
                    title: this.$t('payrollCalculation.remarks'),
                    slot: 'remarks',
                    minWidth: 250
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/payrollCalculation/fetch', { ...attributes })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        clearEdit () {
            this.showForm = false
        },
        addNew () {
            this.$store.commit('payroll/payrollCalculation/SET_EDIT_DATA', {})
            this.showForm = true
            this.$refs.form_action.clearInput()
            this.$refs.form_action.fetchResource({
                fnName: 'orgStructure,shift,employeeProfile'
            })
        },
        onChangeYear (year) {
            this.year = year
            this.fetchData({
                filter: {
                    cycle_year: year,
                    cycle_month: this.month
                }
            })
        },
        onChangeMonth (month) {
            this.month = month
            this.fetchData({
                filter: {
                    cycle_month: month,
                    cycle_year: this.year
                }
            })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PAYROLL CALCULATION',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500),
        cycle_month: function (val) {
            this.month = moment(val).format('MM')
        },
        cycle_year: function (val) {
            this.month = moment(val).format('YYYY')
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData({
                filter: {
                    cycle_month: vm.month,
                    cycle_year: vm.year
                }
            })
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('payroll/payrollCalculation/RESET_STATE')
        this.$store.commit(
            'payroll/payrollCalculation/SET_ORG_CHART_STRUCTURES',
            []
        )
        next()
    }
}
</script>
