<template>
    <div>
        <ts-loading-banner :loading="loading">
            <div class="row mb-3">
                <div class="col-sm-12">
                    <div>
                        <RadioGroup v-model="model.id_type">
                            <Radio class="mt-1" :label="c.value" border v-for="(c, index) in calculateTypes" :key="index">
                                {{ c.label }}
                            </Radio>
                        </RadioGroup>
                    </div>
                </div>
            </div>
            <div class="row mb-3" v-if="model.id_type == 1">
                <div class="col-sm-12">
                    <label class="mb-1 required">{{
                        $t('payrollCalculation.orgChartStructure')
                    }}</label>
                    <div>
                        <Select v-model="model.org_chart_structure_id" filterable :loading="loading" class="from-label"
                            :placeholder="$t('payrollCalculation.orgChartStructure')
                                " :class="{
        'is-invalid': errors.has(
            'org_chart_structure_id'
        )
    }" :clearable="true">
                            <Option v-for="(org, index) in orgStructure" :value="org.org_chart_structure_id" :key="index">
                                {{ org.org_chart_structure }}
                            </Option>
                        </Select>
                        <div class="invalid-feedback" v-if="errors.has('org_chart_structure_id')">
                            {{ errors.first('org_chart_structure_id') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3" v-if="model.id_type == 3">
                <div class="col-sm-12">
                    <label class="mb-1 required">{{
                        $t('payrollCalculation.shiftName')
                    }}</label>
                    <div>
                        <Select v-model="model.shift_id" filterable :loading="loading" class="from-label" multiple
                            :max-tag-count="2" :class="{
                                'is-invalid': errors.has(
                                    'shift_id'
                                )
                            }">
                            <Option v-for="(s, index) in shift" :value="s.shift_id" :key="index">
                                {{ s.shift_name + '-' + s.shift_type }}
                            </Option>
                        </Select>
                        <div class="invalid-feedback" v-if="errors.has('shift_id')">
                            {{ errors.first('shift_id') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3" v-if="model.id_type == 2">
                <div class="col-sm-12">
                    <label class="mb-1 required">{{
                        $t('payrollCalculation.employee')
                    }}</label>
                    <div>
                        <Select v-model="model.employee_id" filterable remote :remote-method="searchEmployee"
                            :loading="loading" class="from-label" multiple :max-tag-count="2" :class="{
                                'is-invalid': errors.has(
                                    'employee_id'
                                )
                            }">
                            <Option v-for="(emp, index) in employeeProfile" :value="emp.employee_id" :key="index">
                                {{
                                    emp.card_id +
                                    '-' +
                                    emp.employee_name_kh +
                                    '-' +
                                    emp.employee_name_en
                                }}
                            </Option>
                        </Select>
                        <div class="invalid-feedback" v-if="errors.has('employee_id')">
                            {{ errors.first('employee_id') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-6">
                    <label class="mb-1 required">{{
                        $t('payrollCalculation.cycleYear')
                    }}</label>
                    <div>
                        <date-Picker :value="model.cycle_year" type="year" format="yyyy" placeholder="Select Year"
                            style="width:320px" @on-change="cycleYearChange" :class="{
                                'is-invalid': errors.has('cycle_year')
                            }">
                        </date-Picker>
                        <div class="invalid-feedback" v-if="errors.has('cycle_year')">
                            {{ errors.first('cycle_year') }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label class="mb-1 required">{{
                        $t('payrollCalculation.cycleMonth')
                    }}</label>
                    <div>
                        <date-Picker :value="model.cycle_month" type="month" placeholder="Select Month" style="width:320px"
                            format="MM" @on-change="cycleMonthChange" :class="{
                                'is-invalid': errors.has('cycle_month')
                            }">
                        </date-Picker>
                        <div class="invalid-feedback" v-if="errors.has('cycle_month')">
                            {{ errors.first('cycle_month') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-6">
                    <div class="form-group m-b-10">
                        <label class="mb-1 required">{{
                            $t('payrollCalculation.cycleOrder')
                        }}</label>
                        <div>
                            <select v-model="model.cycle_order" class="form-control form-select" @change="cycleOrderChange"
                                :class="{
                                    'is-invalid': errors.has('cycle_order')
                                }">
                                <option :value="null">{{ $t('select') }}</option>
                                <option v-for="c in cycleOrders" :key="c.value" :value="c.value">{{ c.text }}</option>
                            </select>
                            <!-- <Select v-model="model.cycle_order" @on-change="cycleOrderChange" :allowClear="true"
                                :loading="loading" class="from-label" :class="{
                                    'is-invalid': errors.has('cycle_order')
                                }">
                                <Option :value="null">{{ $t('select') }}</Option>
                                <Option v-for="c in cycleOrders" :key="c.value" :value="c.value">
                                    {{ c.text }}
                                </Option>
                            </Select> -->
                            <div class="invalid-feedback" v-if="errors.has('cycle_order')">
                                {{ errors.first('cycle_order') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group m-b-10" v-show="model.cycle_order != 1">
                        <label class="mb-1">{{
                            $t('payrollCalculation.exchangeRate')
                        }}</label>
                        <div>
                            <input type="number" v-model="model.exchange_rate" class="form-control" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-text-right">
                <ts-button @click.prevent="calculate" :waiting="saving" color="success" size="lg">
                    <i class="fas fa-hand-holding-usd" v-if="!saving"></i>
                    {{ $t('payrollCalculation.calculate') }}</ts-button>
            </div>
        </ts-loading-banner>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Errors } from 'form-backend-validation'
import moment from 'moment'
import { debounce } from 'lodash'

export default {
    name: 'payroll-calculation-form',
    data() {
        return {
            model: {
                org_chart_structure_id: null,
                shift_id: [],
                cycle_year: moment().format('YYYY'),
                cycle_month: moment().format('MM'),
                cycle_order: null,
                exchange_rate: null,
                id_type: 1,
                employee_id: []
            },
            errors: new Errors(),
            saving: false,
            loading: false,
            calculateTypes: [
                {
                    value: 1,
                    label: 'Organizational Structure'
                },
                {
                    value: 3,
                    label: 'Shift'
                },
                {
                    value: 2,
                    label: 'Employee'
                }
            ],
            cycleOrders: [
                {
                    value: 1,
                    text: 'First Cycle'
                },
                {
                    value: 2,
                    text: 'Second Cycle'
                }
            ],
            orgStructure: [],
            shift: [],
            employeeProfile: []
        }
    },
    methods: {
        ...mapActions('payroll/payrollCalculation', ['getFormViewData']),
        async fetchResource(attributes) {
            this.loading = true
            let response = await this.getFormViewData({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },
        searchEmployee: debounce(async function (query) {
            var avoidSearch = this.employeeProfile.find(
                emp =>
                    emp.card_id +
                    '-' +
                    emp.employee_name_kh +
                    '-' +
                    emp.employee_name_en ==
                    query.trim()
            )
            if (avoidSearch) return
            this.fetchResource({
                fnName: 'employeeProfile',
                emp_search: query
            })
        }, 500),
        calculate() {
            this.errors = new Errors()
            this.saving = true
            this.$store.commit(
                'payroll/payrollCalculation/SET_CYCLE_MONTH',
                this.model.cycle_month
            )
            this.$store.commit(
                'payroll/payrollCalculation/SET_CYCLE_YEAR',
                this.model.cycle_year
            )
            this.$store
                .dispatch('payroll/payrollCalculation/store', this.model)
                .then(response => {
                    this.saving = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('close')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.saving = false
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
        },
        fetchExchangeRate() {
            if (this.model.cycle_order == 2) {
                this.loading = true
                this.getFormViewData({
                    params: {
                        fnName: 'payrollCycle',
                        cycle_month: this.model.cycle_month,
                        cycle_year: this.model.cycle_year,
                    }
                })
                    .then(response => {
                        this.model.exchange_rate = response.payrollCycle.salary_tax_fx_rate
                    })
                    .finally(() => (this.loading = false))
            }
            if (this.model.cycle_order == null) {
                return this.model.exchange_rate = '';
            }
        },
        cycleYearChange(value) {
            this.model.cycle_year = value
            this.fetchExchangeRate()
        },
        cycleOrderChange() {
            this.fetchExchangeRate()
        },
        cycleMonthChange(value) {
            this.model.cycle_month = value
            this.fetchExchangeRate()
        },
        clearInput() {
            this.model.org_chart_structure_id = null
            this.model.shift_id = []
            this.model.cycle_year = moment().format('YYYY')
            this.model.cycle_month = moment().format('MM')
            this.model.cycle_order = null
            this.model.exchange_rate = null
            this.model.id_type = 1
            this.model.employee_id = []
        },
        notice(not) {
            this.$Notice[not.type]({
                title: 'PAYROLL CALCULATION',
                desc: not.text
            })
        }
    },
    watch: {
        'model.id_type': function (value) {
            if (value == 1) {
                this.model.employee_id = []
                this.model.shift_id = []
            } else if (value == 2) {
                this.model.org_chart_structure_id = null
                this.model.shift_id = []
            } else {
                this.model.org_chart_structure_id = null
                this.model.employee_id = []
            }
        }
    }
}
</script>
